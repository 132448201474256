export enum RoodyEmotion {
  COOL = '/roody-emotions/cool-roody.png',
  WARNING = '/roody-emotions/dont-roody.png',
  EXCITED = '/roody-emotions/excited-roody.png',
  HAPPY = '/roody-emotions/happy-roody.png',
  WAITING = '/roody-emotions/hold-up-roody.png',
  SEARCHING = '/roody-emotions/searching-roody.png',
  CONFUSED = '/roody-emotions/what-happened-roody.png',
  SURPRISED = '/roody-emotions/worried-roody.png',
}
